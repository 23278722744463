<template>
	<div class="page">
		<Tables ref="xTable" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('market.zone/add')" addName="新增活动专区" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.key" size="small" clearable placeholder="按活动标题查询">
				</el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="name" title="活动标题" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="platform" title="生效平台" align="center" min-width="30px">
				<template v-slot="{ row }">
					<span>{{$platform[row.platform]}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="subtitle" title="副标题" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="city_text" title="投放城市" align="center" min-width="40px" />
			<vxe-table-column slot="table-item" title="图标" align="center" min-width="50px">
				<template v-slot="{ row }">
					<el-image style="width: 100px; height: 48px;position: relative;left: -15px" :src="row.icon"
						:preview-src-list="viewImgList(row)" fit="contain">
					</el-image>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="tz_type_text" title="跳转方式" align="center" min-width="40px" />
			<vxe-table-column slot="table-item" field="url" title="跳转页面" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" title="有效时间" align="center" min-width="160px">
				<template v-slot="{ row }">
					<span>{{ row.start_time }} - {{ row.end_time }}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="is_show_text" title="是否显示" align="center" min-width="40px" />
			<vxe-table-column slot="table-item" field="sort_num" title="排序↑" align="center" min-width="20px" />
			<vxe-table-column slot="table-item" field="create_time" title="创建时间" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" title="操作" align="center" min-width="50px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.zone/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="deleteOne(row)" v-if="$hasAccess('market.zone/del')">删除
					</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 新增 -->
		<el-dialog title="新增活动专区" :visible.sync="addDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="650px">
			<el-form :model="addDialogFormData" :rules="rules" ref="addForm" label-width="120px" class="form">
				<el-form-item label="活动名称" prop="name">
					<el-input v-model="addDialogFormData.name" style="width: 45%;" size="small"></el-input>
				</el-form-item>
				<el-form-item label="生效平台" prop="platform">
					<el-select v-model="addDialogFormData.platform" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="副标题" prop="subtitle">
					<el-input v-model="addDialogFormData.subtitle" style="width: 45%;" size="small"></el-input>
				</el-form-item>
				<el-form-item label="前端展示ICON" prop="plan_name">
					<upload :fileList="imageList" @getFileInfo="getFileInfo">
					</upload>
					<label style="position: relative;top: -20px;">
						<label>260*230px(宽*高)<span style="color: red">(最大1M)</span></label>
					</label>
				</el-form-item>
				<el-form-item label="投放城市" prop="city">
					<el-select v-model="addDialogFormData.city" placeholder="投放城市" clearable size="small">
						<el-option label="全部" :value="10">
						</el-option>
						<el-option label="苏州" :value="20">
						</el-option>
						<el-option label="无锡" :value="30">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否显示" prop="is_show">
					<el-radio-group v-model="addDialogFormData.is_show">
						<el-radio :label="0">不显示</el-radio>
						<el-radio :label="1">显示</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="跳转类型" prop="tz_type">
					<el-radio-group v-model="addDialogFormData.tz_type">
						<el-radio :label="0">不跳转</el-radio>
						<el-radio :label="20">链接</el-radio>
						<el-radio :label="30">程序内地址</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="链接" prop="content"
					v-if="addDialogFormData.tz_type==20 || addDialogFormData.tz_type==30">
					<el-input v-model="addDialogFormData.url" style="width: 80%;" size="small"></el-input>
				</el-form-item>
				<el-form-item label="活动专区时间" prop="rangeTime">
					<el-date-picker v-model="addDialogFormData.rangeTime" @input="dateSet" value-format="yyyy-MM-dd"
						type="daterange" size="small" clearable>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="排序(小排前)" prop="sort_num">
					<el-input-number :precision="0" :min="0" v-model="addDialogFormData.sort_num" clearable
						size="small" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd()"
					v-if="$hasAccess('market.zone/add')">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 编辑 -->
		<el-dialog title="编辑活动专区" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="650px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="120px" class="form">
				<el-form-item label="活动名称" prop="name">
					<el-input v-model="editDialogFormData.name" style="width: 45%;" size="small"></el-input>
				</el-form-item>
				<el-form-item label="生效平台" prop="platform">
					<el-select v-model="editDialogFormData.platform" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="副标题" prop="subtitle">
					<el-input v-model="editDialogFormData.subtitle" style="width: 45%;" size="small"></el-input>
				</el-form-item>
				<el-form-item label="前端展示ICON" prop="plan_name">
					<upload :fileList="imageList" @getFileInfo="getFileInfoEdit">
					</upload>
					<label style="position: relative;top: -20px;">
						<label>260*230px(宽*高)<span style="color: red">(最大1M)</span></label>
					</label>
				</el-form-item>
				<el-form-item label="投放城市" prop="city">
					<el-select v-model="editDialogFormData.city" placeholder="投放城市" clearable size="small">
						<el-option label="全部" :value="10">
						</el-option>
						<el-option label="苏州" :value="20">
						</el-option>
						<el-option label="无锡" :value="30">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否显示" prop="is_show">
					<el-radio-group v-model="editDialogFormData.is_show">
						<el-radio :label="0">不显示</el-radio>
						<el-radio :label="1">显示</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="跳转类型" prop="tz_type">
					<el-radio-group v-model="editDialogFormData.tz_type">
						<el-radio :label="0">不跳转</el-radio>
						<el-radio :label="20">链接</el-radio>
						<el-radio :label="30">程序内地址</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="链接" prop="content"
					v-if="editDialogFormData.tz_type==20 || editDialogFormData.tz_type==30">
					<el-input v-model="editDialogFormData.url" style="width: 80%;" size="small"></el-input>
				</el-form-item>
				<el-form-item label="活动专区时间" prop="rangeTime">
					<el-date-picker v-model="editDialogFormData.rangeTime" @input="dateSet" value-format="yyyy-MM-dd"
						type="daterange" size="small" clearable>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="排序(小排前)" prop="sort_num">
					<el-input-number :precision="0" :min="0" v-model="editDialogFormData.sort_num" clearable
						size="small" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()"
					v-if="$hasAccess('market.zone/edit')">保 存 编 辑
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'
	export default {
		name: 'market-zone-list',
		components: {
			Tables,
			upload
		},
		data() {
			return {
				isLoading: false,
				tableData: [],
				totalPage: 0,
				imageList: [],
				couponList: [],
				searchFormData: {},
				addDialogShow: false, // 新增
				addDialogFormData: {
					rewards: []
				},
				rules: {
					name: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'change'
					}],
					platform: [{
						required: true,
						message: '请选择生效平台',
						trigger: 'change'
					}],
					subtitle: [{
						required: true,
						message: '请输入副标题',
						trigger: 'change'
					}],
					rangeTime: [{
						required: true,
						message: '请输入有效时间',
						trigger: 'change'
					}],
					city: [{
						required: true,
						message: '请选择投放城市',
						trigger: 'change'
					}],
					tz_type: [{
						required: true,
						message: '请选择跳转方式',
						trigger: 'change'
					}],
					is_show: [{
						required: true,
						message: '请选择是否显示',
						trigger: 'change'
					}],
				},
				editDialogShow: false, // 编辑
				editDialogFormData: {
					rewards: []
				},
				editNeedData: ['id', 'name']
			}
		},
		computed: {
			viewImgList() {
				return function(row) {
					return [row.icon]
				}
			}
		},
		methods: {
			dateSet(v) { //设置日期范围
				this.$forceUpdate();
			},
			getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				let searchParams = {
					_keywords: this.searchFormData.key
				};
				this.isLoading = true
				this.$api.Market.GetZoneList(params, searchParams).then(res => {
					this.totalPage = res.total
					this.tableData = res.data
					for (let d of this.tableData) {
						d.rangeTime = [d.start_time, d.end_time];
					}
					this.isLoading = false
				}).catch(err => {
					this.isLoading = false
				})
			},
			clearSearch() {
				this.searchFormData = {
					key: '',
				}
			},
			// 重置表单
			resetFormData() {
				this.addDialogFormData = {};
				this.imageList = [];
				this.editDialogFormData = {};
				if (this.$refs.addForm) {
					this.$refs.addForm.resetFields();
				}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields();
				}
			},
			//添加
			addOne(row) {
				this.resetFormData();
				this.addDialogShow = true;
			},
			//编辑
			editOne(row) {
				this.resetFormData();
				this.editDialogShow = true;
				this.editDialogFormData = Object.assign({}, row);
			},
			//删除
			deleteOne(row) {
				const params = {
					token: this.$store.state.user.token,
					id: row.id
				}
				this.$api.Market.DeleteZone(params).then(() => {
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				});
			},
			// 新增保存
			saveAdd() {
				this.$refs.addForm.validate((vali) => {
					if (!vali) return;
					if (!this.addDialogFormData.attach_id) {
						this.$message.warning('请上传ICON')
						return;
					};
					this.addDialogFormData.start_time = this.addDialogFormData.rangeTime[0];
					this.addDialogFormData.end_time = this.addDialogFormData.rangeTime[1];
					const form = {
						name: this.addDialogFormData.name,
						subtitle: this.addDialogFormData.subtitle,
						city: this.addDialogFormData.city,
						attach_id: this.addDialogFormData.attach_id,
						start_time: this.addDialogFormData.start_time,
						end_time: this.addDialogFormData.end_time,
						is_show: this.addDialogFormData.is_show,
						tz_type: this.addDialogFormData.tz_type,
						url: this.addDialogFormData.url,
						sort_num: this.addDialogFormData.sort_num,
						platform: this.addDialogFormData.platform,
					}
					const params = {
						token: this.$store.state.user.token,
						form: JSON.stringify(form),
					}
					this.$api.Market.AddZone(params).then(() => {
						this.$notify({
							title: "成功",
							message: "添加成功",
							type: "success",
						});
						this.closeDialog();
						this.$refs.xTable.refreshTable()
					});
				})
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate((vali) => {
					if (!vali) return;
					this.editDialogFormData.start_time = this.editDialogFormData.rangeTime[0];
					this.editDialogFormData.end_time = this.editDialogFormData.rangeTime[1];
					const params = {
						token: this.$store.state.user.token,
						form: JSON.stringify(this.editDialogFormData)
					}
					this.$api.Market.EditZone(params).then(() => {
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					});
				})
			},
			//附件
			getFileInfo(fileInfo) {
				if (this.addDialogFormData.attach_id && this.addDialogFormData.attach_id == fileInfo && this
					.addDialogFormData.attach_id.indexOf(",") == "-1") {
					//执行删除
					const params = {
						token: this.$store.state.user.token,
						attach_id: this.addDialogFormData.attach_id
					}
					this.$api.Ad.DeleteImg(params);
					this.addDialogFormData.attach_id = "";
					return;
				}
				this.addDialogFormData.attach_id = fileInfo;
			},
			// 附件
			getFileInfoEdit(fileInfo) {
				this.editDialogFormData.attach_id = fileInfo;
			},
			// 关闭弹窗
			closeDialog() {
				this.addDialogShow = false
				this.editDialogShow = false
			},
		}
	}
</script>
<style>
	.coupon-choose-form {
		.coupon-item {
			display: flex;
			width: 100%;
			padding: 10px 10px;

			.select {
				margin-left: 5px;
				width: 80px;
			}

			.input {
				margin-left: 5px;
				width: 80px;
			}

			.del-button {
				margin-left: 10px;
			}
		}
	}
</style>
